<script setup lang="ts">
const i18nScope = useLocale('pages');
const logo = useLogo();
const instancePath = useInstancePath();

interface Props {
  type: string;
  image?: string;
}

const props = withDefaults(defineProps<Props>(), {
  image: undefined,
});

const imagePath = `/static/svg/${props.image || props.type}.svg`;
const description = i18nScope(`error${props.type}.meta.description`);
</script>
<template>
  <NuxtLayout name="public">
    <div class="l-wrapper error-page__wrapper">
      <!-- Header -->
      <div class="grid grid-cols-12 gap-12 items-start">
        <div class="col-span-12 | border-blue">
          <div class="l-header -sticky">
            <div class="l-header__logo">
              <a :href="instancePath">
                <img
                  :src="logo.src"
                  :alt="logo.alt"
                >
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- HEADER END -->
      <div class="grid grid-cols-12 items-center justify-between gap-4 error-page">
        <div class="col-start-2 col-span-3">
          <NuxtLink
            :to="instancePath"
            active-class="active"
            exact-active-class="active"
          >
            <HButton
              :label="$t('header.main_menu.back_instance.title')"
              variant="tertiary"
              icon="chevron-left"
              exact-active-class="active"
            />
          </NuxtLink>
          <h2 class="lg mt-12 t-t3">
            <span class="block text-[10rem]">{{ type }}</span>
            Ooops!
            <br>
            {{ i18nScope(`error${type}.title`) }}
          </h2>
          <p
            v-if="description"
            class="error-page__description"
          >
            {{ description }}
          </p>
        </div>
        <div class="col-span-6 col-end-12">
          <img
            :src="imagePath"
            :alt="`Error ${type} image`"
            class="m-auto object-contain"
          >
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>
